.gnav-cart-v1 {
  .cart-overlay-products {
    .prod {
      .prod-info {
        .prod-details {
          .qty-price.right-copy {
            float: left;
            text-align: left;
            width: 100px;
            span.price {
              float: right;
              text-align: right;
            }
            span.qty {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

#past_purchases {
  .past-purchases {
    .accordion-content {
      .shipments-list {
        .products {
          .product-footer {
            .total {
              color: gray !important;
            }
          }
        }
      }
    }
  }
}

.contact_us {
  #contact_us {
    ul.category {
      li {
        width: 150px;
        padding-left: 5px;
        min-height: 135px;
        p.copy {
          font-size: 25px;
        }
        &.online_order {
          div.off {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll 0px -39px;
          }
          div.on {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll 0px -3px;
          }
          .portrayal {
            width: 29px;
          }
        }
        &.beauty_product {
          div.off {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll -27px -39px;
          }
          div.on {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll -27px -3px;
          }
          .portrayal {
            width: 19px;
          }
        }
        &.burial_service {
          div.off {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll -113px -39px;
          }
          div.on {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll -113px -3px;
          }
          .portrayal {
            width: 32px;
          }
        }
        &.product_inquiry {
          div.off {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll -45px -39px;
          }
          div.on {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll -45px -3px;
          }
          .portrayal {
            width: 35px;
          }
        }
        &.ingredients_contact {
          div.off {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll -82px -39px;
          }
          div.on {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll -82px -3px;
          }
          .portrayal {
            width: 32px;
          }
        }
        &.website_tech {
          div.off {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll -143px -39px;
          }
          div.on {
            background: url('/media/export/images/contact_us_qts_category.png') no-repeat scroll -143px -3px;
          }
          .portrayal {
            width: 38px;
          }
        }
      }
    }
    .question_header {
      .caret {
        width: 150px;
      }
      &.beauty_product {
        padding-left: 167px;
      }
      &.product_inquiry {
        padding-left: 322px;
      }
      &.ingredients_contact {
        padding-left: 487px;
      }
      &.burial_service {
        padding-left: 650px;
      }
      &.website_tech {
        padding-left: 784px;
        .caret {
          width: 200px;
        }
      }
    }
    #contact-form-wrap {
      .second-column-wrap {
        .registered_member_container {
          width: 100%;
        }
        .accept_privacy_container {
          float: left;
          margin-top: 10px;
          width: 100%;
          input.field,
          label {
            margin-top: 15px;
          }
        }
        .button_container {
          width: auto;
        }
      }
    }
  }
}

.pc_spp_view {
  #sticky-bar {
    #sticky_bar_content {
      .sticky_content {
        .sticky_prod_select {
          padding-right: 180px;
        }
      }
    }
  }
  .module-spp-detail {
    .page-spp {
      .col2 {
        .price {
          .productsizetitle {
            margin-left: 15px;
            font-size: 15px;
          }
          .productsize {
            margin-left: 4px;
            font-size: 15px;
          }
        }
      }
    }
  }
}

header#header {
  .top-right {
    .inner {
      float: none;
      .block-template-offer-promo-v1 {
        width: 36%;
        .cycle-slideshow {
          .cycle-carousel-wrap {
            width: 100%;
            .offer-promo-v1 {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .outer-wrap {
    .branding.column {
      .site-logo-responsive-v1 {
        a.left_logo-link {
          float: left;
        }
        div.right_logo-link {
          float: right;
          height: 72px;
          line-height: 72px;
        }
      }
    }
  }
}

nav#navigation {
  .outer-wrap {
    .col {
      h2 {
        font-weight: bold !important;
      }
    }
  }
}

html.ie7 {
  .dots {
    width: 47px !important;
    .item.first {
      margin-bottom: 2px;
      height: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .site-logo-responsive-v1 a {
    padding: 0 10px !important;
  }
}

.pc_spp_view #sticky-bar {
  #sticky_bar_content {
    .sticky_content {
      .sticky_prod_select {
        padding-right: 175px;
        .spp_product_status {
          float: left;
          margin-top: 10px;
          margin-left: 10px;
          margin-right: 10px;
          color: #ff3b89;
        }
      }
    }
  }
}

#confirm {
  #newsletter-info {
    form {
      fieldset.email_promotions label {
        color: gray !important;
      }
    }
  }
}

.module-may-we-suggest {
  .grid {
    ul.product-grid {
      li.box {
        .product {
          a {
            .product-image {
              width: auto;
            }
          }
        }
      }
    }
  }
}

.hidden.hide_shaded_sized {
  display: none !important;
}

.pc_spp_view {
  .module-spp-detail {
    .page-spp .col2 {
      .price .productsize.productsize_shaded_sized {
        font-size: 18px;
        margin-left: 8px;
      }
    }
  }
}

.footer-top-v1 .tel {
  font-size: 13px !important;
}
/* Container */
#overlay-background {
  background-color: $color-black;
  min-width: 1024px;
}

.overlay-container {
  #address_form_container {
    input {
      color: inherit;
      display: block;
      float: none;
      font-family: inherit;
      font-size: inherit;
      height: 3em;
      margin: inherit;
      text-transform: inherit;
    }
    select {
      @include swap_direction(margin, 0 15px 10px 0);
      color: inherit;
      display: block;
      float: none;
      font-family: inherit;
      font-size: inherit;
      height: 3em;
      position: relative;
      text-transform: inherit;
    }
    #profile-postal {
      width: auto;
      margin-bottom: 1em;
      .fieldset-note {
        vertical-align: middle;
        display: inline-block;
        width: 35%;
        margin-left: 5px;
      }
      .address_lookup_submit {
        width: 100%;
      }
      width: 100%;
    }
    input.input_postal_code {
      display: inline;
      @include swap_direction(margin, 4px 0 0 0);
      vertical-align: middle;
      width: 50% !important;
    }
    input.input_default {
      display: inline;
    }
    fieldset.fs {
      div.form_element.radio {
        input {
          display: inline;
          margin-right: 10px;
          width: 0;
        }
      }
      #form--address--field--DEFAULT_SHIPPING {
        width: auto;
        float: #{$ldirection};
        @include swap_direction(margin, 2px 5px 10px 0);
      }
      .form_element {
        label {
          width: auto;
        }
      }
    }
    .required-message {
      clear: both;
      margin-bottom: 10px;
    }
    #address-form {
      float: #{$ldirection};
      width: 270px;
      h2 {
        @include swap_direction(margin, 2px 0 2px 0);
        font-size: 20px !important;
      }
      width: 100%;
      @include swap_direction(padding, 10px 10px 10px 10px);
    }
    h2 {
      float: #{$ldirection};
      clear: both;
    }
    fieldset.fs {
      .full_name_container {
        float: none;
      }
      .address1_container {
        float: none;
        clear: both;
      }
      .address2_container {
        float: none;
      }
      .address3_container {
        float: none;
      }
      div.phone_part_area_code {
        select {
          width: 100% !important;
          float: #{$ldirection};
        }
        width: 35%;
      }
      div.form_element {
        @include swap_direction(margin, 5px 0 5px 0);
        input {
          width: 100%;
        }
        width: 100%;
      }
      width: 100%;
      @include swap_direction(margin, 5px 0 5px 0);
      @include swap_direction(padding, 0 0 0 0);
    }
    fieldset.address {
      div.phone_part {
        input {
          width: 90% !important;
        }
        margin-right: 5px !important;
      }
      #profile-postal {
        input {
          width: 35%;
        }
        width: 100%;
      }
    }
    .submit_btn {
      @include swap_direction(margin, 0 10px 0 10px);
    }
    font-size: 11px;
    #address-lookup-container {
      .rna-list-header {
        li {
          width: 99.5%;
          display: block;
          float: #{$ldirection};
          border: 1px solid #f6f0e6;
          &:first {
            background-color: #ddd9c3;
          }
        }
        header1 {
          width: 73.7%;
          float: #{$ldirection};
          text-align: center;
          @include swap_direction(padding, 10px 0 10px 0);
        }
        header2 {
          float: #{$ldirection};
          text-align: center;
          @include swap_direction(padding, 10px 20px 10px 20px);
          border-left: 1px solid #f6f0e6;
        }
        display: none;
        text-align: center;
        font-weight: bold;
        font-size: 13px;
      }
      #rna-loading {
        width: 60px;
        @include swap_direction(margin, 0 auto 0 auto);
        display: none;
      }
      .address {
        input.town-submit-btn {
          width: auto !important;
          @include swap_direction(margin, 5px 0 0 0);
        }
        @include swap_direction(padding, 5px 5px 5px 5px);
        border: none;
      }
      .address-search {
        border-bottom: 1px solid $color-cl-green;
        color: $color-black;
        @include swap_direction(margin, 0 5px 0 5px);
        @include swap_direction(padding, 0 0 5px 0);
        width: 97%;
        #rna_keyword {
          float: #{$ldirection};
          width: 80%;
          @include swap_direction(margin, 0 5px 0 5px);
        }
        h4 {
          font-size: 16px;
        }
        .keyword-hint {
          padding: 5px 0;
          font-weight: normal;
          clear: both;
        }
        h3 {
          font-size: 10px;
          color: $color-black;
          font-weight: bold;
        }
      }
      .grey-button {
        display: block;
        text-align: center;
        width: auto;
        background-color: #999;
        color: $color-white;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
        @include swap_direction(padding, 12px 10px 12px 10px);
        font-weight: bold;
        height: 25px;
        line-height: 3px;
        @include swap_direction(margin, 3px 0 3px 0);
      }
      #rna-page-list {
        float: #{$ldirection};
        @include swap_direction(margin, 0 0 0 0);
        @include swap_direction(padding, 10px 0 10px 0);
        width: 100%;
        text-align: center;
      }
      .error-msg {
        display: none;
        @include swap_direction(margin, 0 auto 0 auto);
        width: 35%;
        @include swap_direction(padding, 30px 0 30px 0);
      }
      .error_messages {
        display: none;
        @include swap_direction(margin, 0 auto 0 auto);
        @include swap_direction(padding, 30px 0 30px 0);
        width: 70%;
        color: $color-black;
      }
      .error-display {
        width: 95%;
        background-color: #f7f7f7;
        @include swap_direction(margin, 10px 10px 10px 10px);
      }
      .error_messages.error {
        @include swap_direction(margin, 0 auto 0 auto);
        width: 35%;
        @include swap_direction(padding, 30px 0 30px 0);
        li {
          color: $color-black;
        }
      }
      .address1 {
        display: block;
        @include swap_direction(padding, 5px 0 5px 0);
        cursor: pointer;
      }
      .address3 {
        cursor: pointer;
      }
      .rna-list {
        display: none;
        border: none;
        @include swap_direction(margin, 0 0 0 0);
        width: 100%;
        tbody {
          max-height: 215px;
          overflow-y: auto;
          overflow-x: hidden;
          display: inline-block;
        }
        .sno {
          width: 14px;
        }
        th.full-address {
          text-align: center;
        }
        .full-address {
          width: 500px;
        }
        .postal {
          width: 115px;
        }
        th {
          line-height: 15px;
          height: 15px;
        }
        tr {
          border-top: 1px solid $color-light-grey;
          border-bottom: 1px solid $color-light-grey;
          display: block;
        }
        .roadAddrPart1 {
          display: none;
        }
        .roadAddrPart2 {
          display: none;
        }
      }
      #address-hint-container {
        h4 {
          padding-left: 10px;
          font-weight: bold;
          font-size: 10px;
          @include swap_direction(margin, 10px 0 5px 0);
          color: $color-black;
        }
        background-color: $color-white;
        padding-top: 5px;
        .hint-table {
          width: 95%;
          border-collapse: collapse;
          @include swap_direction(margin, 0 10px 0 10px);
          border-top: 1px solid $color-light-grey;
          border-bottom: 1px solid $color-light-grey;
          @include swap_direction(padding, 10px 10px 10px 10px);
          font-size: 10px;
          .hint-table-header {
            border-top: 1px solid $color-light-grey;
            border-bottom: 1px solid $color-light-grey;
            @include swap_direction(padding, 10px 10px 10px 10px);
            font-size: 10px;
            background-color: #f7f7f7;
          }
          .hint-table-data {
            border-top: 1px solid $color-light-grey;
            border-bottom: 1px solid $color-light-grey;
            @include swap_direction(padding, 10px 10px 10px 10px);
            font-size: 10px;
          }
        }
        .building-name-registered {
          @include swap_direction(padding, 10px 10px 10px 10px);
          @include swap_direction(margin, 0 0 0 0);
        }
      }
      #address-results-container {
        background-color: $color-white;
        padding-top: 5px;
        display: none;
        .rna-list {
          width: 95%;
          border-collapse: collapse;
          @include swap_direction(margin, 0 10px 0 10px);
          border: none !important;
          border-top: 1px solid $color-light-grey;
          border-bottom: 1px solid $color-light-grey;
          @include swap_direction(padding, 0 10px 10px 10px);
          font-size: 10px;
          th {
            border: none !important;
            border-top: 1px solid $color-light-grey;
            border-bottom: 1px solid $color-light-grey;
            @include swap_direction(padding, 10px 10px 10px 10px);
            font-size: 10px;
            background-color: #f7f7f7;
          }
          td {
            border: none !important;
            border-top: 1px solid $color-light-grey;
            border-bottom: 1px solid $color-light-grey;
            @include swap_direction(padding, 10px 10px 10px 10px);
            font-size: 10px;
          }
        }
        zipcode {
          float: #{$rdirection};
        }
        .search-results-header {
          padding-left: 10px;
          color: $color-black;
          font-weight: bold;
          margin-left: 10px;
        }
      }
      #rna-page-list {
        background-color: $color-white;
        padding-top: 5px;
      }
      #address-third-elements {
        .zip {
          display: none;
        }
      }
      #address-input-container {
        h4 {
          font-size: 14px;
          @include swap_direction(padding, 0 0 5px 10px);
          color: $color-black;
          font-weight: bold;
        }
        label {
          display: block;
          float: #{$ldirection};
          width: 20%;
          font-weight: bold;
        }
        .building {
          @include swap_direction(margin, 0 auto 0 auto);
          width: 57%;
          .green-btn.small1.check-balance {
            @include swap_direction(margin, 10px 55px 10px 55px);
            float: #{$ldirection};
          }
        }
        ra3 {
          @include swap_direction(padding, 5px 0 5px 0);
          display: block;
        }
        .address-search-h {
          @include swap_direction(margin, 5px 0 5px 0);
        }
        #addr2_input_node {
          width: 75%;
        }
        .street {
          @include swap_direction(margin, 10px 10px 10px 10px);
        }
        .detail-address {
          @include swap_direction(margin, 10px 10px 10px 10px);
        }
        .address-details {
          background-color: #f7f7f7;
          @include swap_direction(padding, 10px 10px 10px 10px);
          @include swap_direction(margin, 0 10px 0 10px);
        }
        .address-submit-button {
          @include swap_direction(padding, 10px 10px 10px 10px);
          width: 100%;
          .address-input-submit {
            @include swap_direction(margin, 0 auto 0 auto);
            width: 45%;
            height: 20px;
            line-height: 14px;
          }
        }
      }
    }
  }
  #address-confirmation {
    h2 {
      font-size: 16px;
      color: $color-black;
      @include swap_direction(margin, 0 0 0 0);
    }
    .address-confirm-buttons {
      width: 33%;
      @include swap_direction(margin, 10px auto 0 auto);
      .address-confirm {
        width: 45%;
        float: #{$ldirection};
        .update-confirm {
          width: 100%;
        }
      }
      .address-cancel {
        float: #{$ldirection};
        width: 45%;
        margin-left: 5px;
        .grey-button {
          font-family: $base-bold-font-family;
          background-color: #999;
          text-transform: uppercase;
          color: $color-white;
          line-height: 1.5;
          font-size: 0.9em;
          text-align: center;
          letter-spacing: 0;
          border: 0;
          @include swap_direction(padding, 4px 10px 3px 10px);
          display: inline-block;
          zoom: 1;
        }
        .update-cancel {
          height: 23px;
          @include swap_direction(margin, 0 0 0 0);
          width: 100%;
        }
      }
    }
    width: 100%;
    text-align: center;
    .change-address-content {
      width: 75%;
      @include swap_direction(padding, 10px 0 10px 0);
      line-height: 18px;
      @include swap_direction(margin, 0 auto 0 auto);
    }
  }
  .close-link {
    right: 10px;
    top: 10px;
    @include swap_direction(padding, 5px 5px 5px 5px);
    cursor: pointer;
    position: absolute;
    right: 20px;
  }
  min-width: 33%;
  background-color: $color-white;
}

#order_return {
  #address_form_container {
    label {
      width: 98%;
      display: block;
    }
    input {
      margin: 5px 0;
    }
    span.required_mark {
      float: left;
    }
    fieldset {
      @include swap_direction(padding, 0 0 0 0);
      @include swap_direction(margin, 5px 0 5px 0);
      border: 0;
      input {
        width: 52px;
        float: left;
        margin: 0 5px;
      }
      select {
        float: left;
      }
    }
    .address_lookup_submit {
      text-decoration: inherit;
      margin: 5px 0;
    }
    #form--address--field--POSTAL_CODE {
      width: 50%;
    }
  }
}

#order_return_confirm {
  .margin_top_bottom {
    margin: 0.8em 0;
    padding: 10px;
  }
}

.gnav-my-account-v1 {
  a.sms_button {
    background-color: #5dba98;
    border: 0 none;
    color: white;
    display: inline-block;
    font-size: 0.9em;
    letter-spacing: 0;
    line-height: 1.5;
    padding: 4px 10px 3px;
    text-align: center;
    text-transform: uppercase;
  }
  .all_three_radio_select {
    clear: both;
    padding-top: 10px;
    .form_label {
      width: auto;
    }
  }
  .terms_agree .form-block.required {
    width: 32%;
    float: left;
  }
  .promotion_notes_two {
    color: red;
    font-size: 10px;
  }
}

.section-mascara-finder-collection {
  .mascara-product-v1 {
    .row {
      margin-bottom: 40px;
      margin-top: 40px;
    }
    .product {
      .product-details {
        min-height: 460px;
      }
    }
    .product-info {
      padding-left: 10px;
      padding-right: 45%;
      position: absolute;
      top: 310px;
    }
  }
}

.ingredients_container {
  .ingredients__content {
    font-family: $base-font-family;
  }
}

.spp_page_wrap {
  .container {
    .product-full {
      &__media {
        vertical-align: top;
        display: inline-block;
        @media #{$medium-up} {
          width: 48%;
          display: inline-block;
          margin-bottom: 20px;
        }
        .product-full {
          &__image {
            display: inline-block;
            vertical-align: top;
            margin: 0 auto;
            position: relative;
            max-width: 402px;
            &-wrapper {
              display: inline-block;
              position: relative;
              vertical-align: top;
            }
            &--slide {
              width: 402px;
              height: 464px;
              @media #{$medium-up} {
                display: none;
              }
              &.active {
                @media #{$medium-up} {
                  display: block;
                }
              }
              img {
                width: 402px;
                height: 464px;
              }
            }
            &__video {
              position: relative;
              &.slick-slide {
                img.mobile-hidden {
                  display: none;
                }
              }
              &-overlay {
                background-image: url('/media/images/icons/video_play.png');
                background-repeat: no-repeat;
                background-position: top right;
                padding: 20px;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
              }
              .poster-frame {
                width: 100%;
              }
            }
          }
          &__alt-images {
            display: inline-block;
            vertical-align: top;
            position: relative;
            &-slider {
              display: none;
              margin: 60px 20px 0 0;
              width: 50px;
              @media #{$medium-up} {
                display: none;
              }
              &.active {
                @media #{$medium-up} {
                  display: inline-block;
                }
              }
              .slick-slide {
                border: none;
              }
              .slick-arrow {
                height: 6px;
                width: 11px;
                left: 20px;
                position: relative;
                z-index: 100;
                padding: auto;
              }
              .slick-prev {
                top: -25px;
                background: url('/media/images/icons/spp_thumb_carousel_caret_up.png') no-repeat;
                &:before {
                  content: none;
                }
              }
              .slick-next {
                bottom: -25px;
                background: url('/media/images/icons/spp_thumb_carousel_caret_down.png') no-repeat;
                &:before {
                  content: none;
                }
              }
              &-button {
                height: 6px;
                width: 11px;
                margin: 0 auto;
                cursor: pointer;
              }
            }
            &-thumb {
              padding: 5px 0;
              cursor: pointer;
              border-bottom: 3px solid $color-white;
              &.active {
                border-color: $color-black;
              }
              img {
                width: 50px;
              }
            }
          }
          .badge {
            @include border-radius(50%);
            position: absolute;
            top: 50px;
            #{$ldirection}: 50px;
            display: table;
            width: 65px;
            height: 65px;
            color: $color-white;
            z-index: 1;
            padding: 0;
            line-height: 14px;
            font-family: $base-font-family;
            vertical-align: middle;
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            &--large {
              top: 0;
              left: 0;
            }
            &.badge_1,
            &.badge_2,
            &.badge_3,
            &.badge_4,
            &.badge_5,
            &.badge_17,
            &.badge_92,
            &.badge_94,
            &.badge_96,
            &.badge_97 {
              background: $color-cl-grey;
            }
            &.badge_96 {
              font-size: 12px;
            }
            &.badge_30 {
              background: $color-cl-grey;
            }
            html.ie7 & {
              text-align: center;
            }
            .badge-inner {
              display: table-cell;
              vertical-align: middle;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.sticky_prod_select {
  .giftset_components {
    .product-size {
      &__non-shaded {
        display: none;
      }
    }
  }
}

#zero_dollar_overlay {
  h3 {
    margin-top: 16px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .zero-dollar {
    &-buttons {
      display: flex;
      padding-top: 40px;
      .button {
        margin: 0px auto;
        color: $color-white;
        background-color: $color-black;
      }
    }
    &-success,
    &-error {
      text-align: center;
      font-size: 28px;
      padding-top: 38px;
    }
  }
}

.text-link--style-2-bold,
a.text-link--style-2-bold {
  font-weight: bold;
}
