.pc_spp_view {
  .module-spp-detail {
    .container {
      .col1 {
        .badge.badge_109 {
          background: #fe6cb5 none repeat scroll 0 0;
          line-height: 16px;
        }
      }
    }
  }
}
