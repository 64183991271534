#content .cl-mpp-product-layout-v1 {
  .mpp-grid {
    li.mpp-box {
      .badge.badge_109 {
        background: #fe6cb5 none repeat scroll 0 0;
        line-height: 14px;
      }
    }
  }
}

.module-spp-detail {
  [class^='icon-'] {
    display: inline;
  }
  .icon-message {
    border: none;
    padding: 0;
    &.active {
      border: 1px solid $color-black;
      padding: 5px;
    }
  }
}

.field-collection-slides {
  .content-block {
    overflow: visible;
  }
}
