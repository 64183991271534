/* font-style : ³ª´®°íµñ */
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  src: url('#{$netstorage-font-path}cjk/NanumGothic/NanumGothic-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Nanum Gothic bold';
  font-style: normal;
  font-weight: 700;
  src: url('#{$netstorage-font-path}cjk/NanumGothic/NanumGothic-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Nanum Gothic extra bold';
  font-style: normal;
  font-weight: 800;
  src: url('#{$netstorage-font-path}cjk/NanumGothic/NanumGothic-ExtraBold.woff2') format('woff2');
  font-display: swap;
}

body,
div,
ul,
li,
a,
p,
span,
table,
tr,
th,
td,
h1,
h2,
h3,
h4,
h5,
input,
form,
dl,
dt,
dd,
label,
small,
select,
article,
strong {
  font-family: 'Nanum Gothic', 'Dotum', 'Golum';
}

$base-kr-font-family: 'Nanum Gothic', 'Dotum', 'Golum';
/* body,div,ul,li,a,p,span,table,tr,th,td,h1,h2,h3,h4,h5,input,form,dl,dt,dd{font-family: 'Nanum Gothic' !important, µ¸¿ò, Dotum, ±¼¸², Gulim;} */
