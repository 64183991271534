.ie8 {
  form {
    .form-item {
      input,
      input[type='text'] {
        line-height: 30px !important;
      }
    }
  }
  .bvflow_show {
    .form {
      .form-text {
        height: 36px !important;
      }
    }
  }
  #top {
    #header {
      .top-right {
        .gnav-search-v1 {
          .predictive-search {
            div.form-type-textfield {
              width: auto;
            }
          }
        }
      }
    }
    #navigation {
      .outer-wrap {
        .content {
          .block-template-tout-image-v1 {
            border-left: 1px solid #dbdbdb;
            .tout-image-v1 {
              &.tout {
                position: relative;
                width: 290px;
                float: right;
                border: none;
                min-height: 350px;
                padding-left: 60px;
              }
              .tout-info {
                width: 390px;
                left: -140px;
                position: relative;
              }
            }
          }
        }
      }
    }
    .signin_container {
      .outer-wrap {
        form#register-form {
          input,
          select,
          textarea {
            vertical-align: top !important;
          }
        }
      }
    }
  }
}

.ie7 {
  #header {
    .top-right {
      width: 784px !important;
      .block-template-offer-promo-v1 {
        width: 268px !important;
      }
      .block-template-gnav-search-v1 {
        width: 230px !important;
      }
      .block-template-stores-menu-v1 {
        width: 59px !important;
      }
      .block-template-help-menu-v1 {
        width: 52px !important;
      }
      .block-template-gnav-cart-v1 {
        width: 22px !important;
      }
      .dd-icon {
        position: relative;
      }
      .gnav-search-v1 {
        .form-type-textfield {
          position: relative;
        }
        input.form-submit {
          width: 40px;
        }
        input.form-text {
          height: 40px;
          width: 150px;
          position: absolute;
          left: 0px;
          margin-top: 5px;
          line-height: 28px;
        }
      }
      .offer-promo-v1 {
        position: relative;
        .content {
          top: 20px;
          position: relative;
        }
      }
    }
    .block-nodeblock-26316 {
      .site-logo-responsive-v1 {
        a.left_logo-link {
          img {
            position: relative;
            top: 13px;
          }
        }
      }
    }
  }
  .main {
    .wrap {
      .cl-mpp-product-layout-v1 {
        .mpp-filter {
          .mpp-filter-subheaders {
            display: none !important;
          }
        }
      }
      ul.breadcrumbs {
        li {
          float: left;
        }
      }
      .outer-wrap {
        top: 0px !important;
        .subheading {
          p {
            padding-top: 35px !important;
          }
        }
      }
      .content {
        .cl-mpp-product-layout-v1 {
          .mpp-grid {
            #mpp-product-grid {
              li.mpp-box {
                diplay: block !important;
                float: left !important;
              }
            }
          }
          .mpp-view-options {
            .mpp-view-toggle {
              width: 14% !important;
            }
          }
          section.mpp-filter.collapsed {
            height: 80px !important;
            position: relative;
            .mpp-filter-content {
              width: 100%;
              padding: 40px 0 0 0;
            }
          }
          section.mpp-filter {
            .mpp-filter-column2 {
              ul li.filter-item.group_2,
              ul li.filter-item.group_3,
              ul li.filter-item.group_4 {
                diplay: block !important;
                float: left !important;
                a {
                  min-height: 27px;
                }
              }
            }
          }
        }
        ul.product-grid {
          li.box {
            diplay: block !important;
            float: left !important;
          }
        }
        .col1 {
          .BVRROverallRatingContainer {
            .BVRRRatingNormalCustomReviewCount {
              float: left;
            }
          }
          .BVRRRatingSummaryLinks {
            float: left;
            margin-top: 5px;
            div {
              position: relative;
            }
          }
          .BVRRSocialBookmarkingLinks {
            float: left;
            margin-left: 20px;
            a.BVRRSocialBookmarkingSharingLink {
              position: relative;
            }
          }
        }
      }
    }
  }
  .viewcart-header {
    .cart-item-rows {
      .name {
        width: 200px !important;
      }
    }
  }
  #top {
    #navigation {
      .outer-wrap {
        .content {
          .block-template-tout-image-v1 {
            border-left: 1px solid #dbdbdb;
            width: 39% !important;
            .tout-image-v1 {
              &.tout {
                position: relative;
                width: 290px;
                float: right;
                border: none;
                min-height: 350px;
                padding-left: 60px;
              }
              .tout-info {
                width: 390px;
                left: -140px;
                position: relative;
              }
            }
          }
        }
      }
      .navigation-right {
        .block-nodeblock-26306 {
          ul.mm-menu li {
            span.title {
              span {
                border-right: 1px solid #000000;
              }
            }
          }
        }
      }
    }
  }
  #footer {
    .footer-top {
      .footer-top-v1 {
        .tel,
        .social-links {
          float: left;
        }
      }
    }
  }
  .col1 {
    .badge {
      .badge-inner {
        margin-top: 24px !important;
      }
    }
  }
}

.oldie {
  .dots {
    .item.dot {
      border: 1px solid #5d5d5d;
    }
    .item.dot.active {
      border: 1px solid #5d5d5d;
      background-color: #5d5d5d;
    }
    .item.dot.hover {
      border: 1px solid #5d5d5d;
      background-color: #5d5d5d;
    }
  }
}

.pc_spp_view {
  #sticky_bar_content {
    .sticky_content {
      .sticky_prod_select {
        .sbHolder {
          width: 200px !important;
        }
      }
    }
  }
  .module-spp-detail {
    .container {
      strong {
        font-weight: bold;
      }
    }
  }
}
