.store-locator-body {
  #store-search-controls {
    .city_name_container {
      line-height: 28px;
      font-weight: bold;
    }
    .state_container {
      margin: 0 20px;
      .sbHolder {
        height: 30px;
        line-height: 18px;
      }
      .sbToggle {
        top: 4px;
      }
      .sbSelector {
        height: 28px;
        line-height: 28px;
      }
      ul.sbOptions {
        max-height: 217px !important;
        border-bottom: solid 1px #dddcdc;
      }
    }
  }
  .locations_table_panel {
    table.doors_results {
      border-top: 1px solid #cccccc;
      margin-top: 10px;
      td {
        z-index: 0;
        .store_name {
          color: #000000;
        }
      }
      tr th {
        padding-top: 10px;
      }
    }
  }
}

.ie7 {
  .store-locator-body {
    .locations_table_panel {
      table.doors_results {
        td {
          z-index: -999;
          position: static !important;
        }
      }
    }
  }
}
