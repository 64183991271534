#services {
  .services-container {
    .service {
      min-height: 465px;
    }
  }
}

.appointments-container {
  #booking-step3 {
    .book-appt-container {
      .appt_booking_helpers {
        float: right;
        width: 48.2%;
      }
      .terms-col {
        float: left;
        width: 48%;
        height: 180px;
        + .terms-col {
          margin-left: 4%;
        }
      }
      .radio_group {
        &.sms_block,
        &.email_block {
          width: 25%;
          float: left;
        }
      }
    }
  }
}

.appt-book-overlay {
  &.appt-book-datepicker-overlay {
    .overlay-content-container {
      top: 120px;
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .service {
        .service-head {
          min-height: 120px;
        }
      }
    }
  }
  .appt-book-page-header {
    .appt-book-page-header-content {
      top: 20%;
      #{$ldirection}: 7%;
      .inner {
        display: inline-block;
        .appt-book-page-header-content {
          &__subhead {
            h4 {
              font-size: 17px;
              line-height: 1.41176;
            }
          }
        }
      }
      h2 {
        font-size: 2.92vw;
        font-weight: bold;
        color: $color-black;
      }
    }
  }
  .service-select {
    .service {
      &-details {
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }
}
